import Vue from 'vue'

import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true

export default {
  state: () => ({
    user: null,
  }),
  mutations: {
    set(state, [variable, value]) {
      state[variable] = value
    },
    SET_USER(state, value) {
      state.user = value;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('set_user', user);
    },
    async getUser({ commit }) {
      await axios.post(Vue.prototype.$base_api_url + '/user').then(async res => {
        console.log('User', res);
        await commit('SET_USER', res.data);
      }).catch(async () => {
        await commit('SET_USER', null);
      });
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  }
}
