<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    UpgradeToPro,
  },
  data() {
    return {}
  },
  computed: {
    resolveLayout() {
      const { route } = useRouter()
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    },
    getUser() {
      return this.$store.getters.getUser
    },
  },
  watch: {
    getUser(newUser) {
      if (newUser && newUser.role != 'client') {
        window.location.href = this.$url_server
      }
    },
  },
}
</script>
