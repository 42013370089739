import _ from 'lodash';
import moment from 'moment';
moment.locale('es-mx');
//Version 1.3
/**
 * En esta version se corrigio el catchResponse para mostrar errores de inputs que no esten el el namespace
 */

export default {
  methods: {
    /**
     * Esta funcion se encarga de atrapar los errores
     * @param {Error} e
     * @param {Object} options
     * {
     *  namespace: 'inputs',
     *  customEvents: {
     *    500: {Callback}
     *    ...
     *  }
     * }
     */
    catchResponse(e, options) {
      let customEvents = options !== undefined ?
        (options.customEvents !== undefined && typeof options.customEvents == 'object' ? options.customEvents : {})
        : {};


      if (e.response !== undefined) {
        let namespace = options !== undefined ? options.namespace !== undefined ? options.namespace : 'inputs' : 'inputs';

        //Si si hay evento personalizado para este codigo de error
        if (e.response.status !== undefined && customEvents[e.response.status] && typeof customEvents[e.response.status] == 'function') {
          customEvents[e.response.status](e);
          return;
        }

        //En caso de que exista errores en el formulario
        let v = this;
        if (e.response.status == 422) {
          let errors = e.response.data.errors;

          if (Array.isArray(errors)) {
            //Si los errores vienen en un array
            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              text: `${errors.join('<br>')}`,
              icon: `error`,
              showClass: {
                popup: this.isDark() ? 'theme-dark' : ''
              }
            });
          } else if (typeof errors == 'string') {
            //Si el error es unico
            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              text: `${errors}`,
              icon: `error`,
              showClass: {
                popup: this.isDark() ? 'theme-dark' : ''
              }
            });
          } else {
            let aditionalErros = []
            //Si lo errores vienen en un objeto
            _.forEach(errors, function (value, index) {

              //Si hay algun error de errores se concatena en solo un array
              let errors2 = [];
              _.forEach(value, e => {
                if (Array.isArray(e)) {
                  errors2 = errors2.concat(e);
                } else {
                  errors2.push(e);
                }
              });
              value = errors2;

              if (v[namespace]) {
                if (v[namespace][index] !== undefined) {
                  v[namespace][index].errors = value
                } else {
                  const some = _.some(v[namespace], function (value2, key) {
                    if (value2 !== null && value2.key !== undefined && value2.key == index) {
                      v[namespace][key].errors = value;
                      return true;
                    }
                    return false;
                  });
                  if (!some) {
                    aditionalErros = aditionalErros.concat(value)
                  }
                }
              } else {
                aditionalErros = aditionalErros.concat(value)
              }
            });

            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              html: `Hay errores en el formulario. ${aditionalErros.length > 0 ? '<br>' + aditionalErros.join('<br>') : ''}`,
              icon: `error`,
              showConfirmButton: aditionalErros.length > 0,
              timer: aditionalErros.length > 0 ? undefined : 3000,
              backdrop: false,
            });
          }

        } else if (e.response.status == 419) {
          this.$swal.fire({
            title: `Error`,
            text: `Error 419, No te reconocemos, por favor recarga la pagina.`,
            icon: `warning`,
            showClass: {
              popup: this.isDark() ? 'theme-dark' : ''
            }
          });
        } else if (e.response.status == 401) {
          this.$swal.fire({
            title: `Warning`,
            text: `Error 401, No tienes permiso para acceder a este recurso.`,
            icon: `warning`,
            showClass: {
              popup: this.isDark() ? 'theme-dark' : ''
            }
          });
        } else {
          if (e.response.status != undefined) {
            let message = e.response.message != undefined ? e.response.message : `Esto no debia de haber pasado, Intenta mas tarde`;

            this.$swal.fire({
              title: `Error`,
              text: `Error(${e.response.status}): ${message}`,
              icon: `error`,
              showClass: {
                popup: this.isDark() ? 'theme-dark' : ''
              }
            });
          } else {
            //Evento por defecto
            if (e.response != undefined && e.response.status !== undefined && customEvents.default && typeof customEvents.default == 'function') {
              customEvents.default(e);
              return;
            }
            this.$swal.fire({
              title: `Error`,
              text: `Esto no debia de haber pasado, Intenta mas tarde`,
              icon: `error`,
              showClass: {
                popup: this.isDark() ? 'theme-dark' : ''
              }
            });
          }
        }

      } else {
        //Evento por defecto
        if (e.response != undefined && e.response.status !== undefined && customEvents.default !== undefined && typeof customEvents.default == 'function') {
          customEvents.default(e);
          return;
        }

        this.$swal.fire({
          title: `Error`,
          text: `Esto no debia de haber pasado, Intenta mas tarde`,
          icon: `error`,
          showClass: {
            popup: this.isDark() ? 'theme-dark' : ''
          }
        });

      }
    },
    resetErrors(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';
      _.forEach(this[namespace], (value, index) => {
        this[namespace][index].errors = [];
      });
    },
    resetInputs(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';

      _.forEach(this[namespace], (value, index) => {
        if (this[namespace][index].default !== undefined) {
          this[namespace][index].value = this[namespace][index].default;
        } else {
          if (this[namespace][index].value !== undefined) {
            this[namespace][index].value = null;
          }
        }

      });
    },
    trimInputsText(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';

      _.forEach(this[namespace], (value, index) => {
        if (typeof this[namespace][index].value == 'string') {
          this[namespace][index].value = this[namespace][index].value.trim();
        }
      });
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    diffNow(date, type) {
      var fecha1 = moment(date);
      var fecha2 = moment();

      return fecha1.diff(fecha2, type);
    },
    formatTime(date) {
      return moment(date).format('HH:mm');
    },
    formatDatetime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    storagePrivateLink(path) {
      return '/private/storage?file=/' + path;
    },
    storagePrivateLinkApi(path) {
      return this.$base_api_url + '/private/storage?file=/' + path;
    },
    formatPrice(price) {
      return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price);
    },
    /**
     * Esa funcion nos ayuda a generar una estructura aceptada por los inputs de tipo select
     * de vutify, generando aparte de un pbjeto llave -> valor, un array de objetos
     * @param {Objets} data Un objeto de tipo llave -> valor
    */
    objectToItemsSelect(data) {
      return _.map(data, (valor, key) => {
        return {
          text: valor,
          value: key
        }
      })
    },
    formatFolio(folio) {
      return folio.toString().padStart(7, 0);
    },
    /**
     * Esta funcion busca dentro de un arrays de objetos con los atributos value, text
     * busca por el atributo value y regresa el atributo text, null en caso de no extistir o el
     * parametro opcional
     */
    searchInSelectFormat(items, search, other) {

      if (Array.isArray(items) && items.includes(search)) {
        return search;
      }
      let toFind = _.find(items, item => {
        return item.value == search;
      });

      if (toFind) {
        return toFind.text;
      } else {
        console.warn('No se encontro el valor: ' + search);
        return other !== undefined ? other : null;
      }
    },
    objectToVSelectItems(data) {
      return _.map(data, (item, key) => {
        return {
          text: item,
          value: key,
        }
      });
    },
    parseJson(text) {
      if (typeof text == 'string') {
        return JSON.parse(text);
      }
      return text;
    },
    scrollTop(identifier) {
      let components = document.querySelector(identifier)
      components.scrollTop = 0
    },
    isDark() {
      return this.$vuetify.theme.dark;
    }
  },
  computed: {
    getDefaultImage() {
      if (this.$vuetify.theme.dark) {
        return require('@/assets/images/no-image-dark.jpg')
      } else {
        return require('@/assets/images/no-image.jpg')
      }
    }
  }
}
