<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <!--<v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>-->

          <v-spacer></v-spacer>

          <!-- Right Content -->

          <theme-switcher></theme-switcher>
          <v-menu offset-y width="fit-content">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small class="ms-3" v-on="on" v-bind="attrs">
                <v-icon>
                  {{ icons.mdiBellOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list class="pt-1">
              <!-- <v-list-item height="fit-content">
                <v-alert class="mb-0 my-1" style="width: 100%;" dense type="info"> Alerta de información </v-alert>
              </v-list-item>
              <v-list-item height="fit-content">
                <v-alert class="mb-0 my-1" style="width: 100%;" dense type="success"> Alerta de acción satisfactoria </v-alert>
              </v-list-item>
              <v-list-item height="fit-content">
                <v-alert class="mb-0 my-1" style="width: 100%;" dense type="error"> Alerta de error </v-alert>
              </v-list-item>
              <v-list-item height="fit-content">
                <v-alert class="mb-0 my-1" style="width: 100%;" dense type="warning"> Alerta de prevención </v-alert>
              </v-list-item> -->
              <v-list-item height="fit-content">
                Aun no tienes notificaciones.
              </v-list-item>
            </v-list>
          </v-menu>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="70" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> &copy; 2021 <a class="text-decoration-none">Devias logistic</a></span>
          <div class="text-center mb-6">
            <!-- <v-bottom-sheet v-model="getBottomSheet" inset>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue" v-bind="attrs" v-on="on">
                  Ayuda
                  <v-icon class="ml-1">
                    {{ icons.mdiHelpCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <v-sheet class="text-center" height="200px">
                <div class="my-3 pt-8">
                  <h3>¿Necesitas ayuda?</h3>
                  <p>Escribenos a: <a href="mailto:ayuda@deviaslogistic.com">ayuda@deviaslogistic.com</a></p>
                  <h3>¿Tienes dudas sobre el uso de la plataforma?</h3>
                  <p>Ve nuestros videos aquí: <a href="http://">videos</a></p>
                </div>
              </v-sheet>
            </v-bottom-sheet> -->
          </div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  mdiMagnify,
  mdiBellOutline,
  mdiGithub,
  mdiHelpCircleOutline,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  data() {
    return {
      isDrawerOpen: null,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiHelpCircleOutline,
      },
    }
  },
  computed: {
    getBottomSheet: {
      get(){
        return this.$store.getters.getBottomSheet;
      },
      set(value) {
        return this.$store.dispatch('set_bottom_sheet',value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.v-list-item {
  height: fit-content !important;
}
</style>
