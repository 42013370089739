import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import storage  from './plugins/storage'
import axios from 'axios'
import _ from 'lodash'
import VueSweetalert2 from 'vue-sweetalert2';


Vue.config.productionTip = false

Vue.prototype.$url_server = process.env.VUE_APP_URL_SERVER
Vue.prototype.$base_api_url = process.env.VUE_APP_BASE_API_URL
Vue.prototype.$base_api_sanctum_url = process.env.VUE_APP_BASE_API_SANCTUM_URL

Vue.prototype.$http = axios
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true

Vue.prototype.$_ = _

Vue.use(VueSweetalert2);
Vue.use(storage);



router.beforeEach(async (to,from,next) => {

  if(to.meta.auth === undefined || to.meta.auth!==false) {

    if(store.getters.getUser == null){
      await store.dispatch('getUser');
      if(store.getters.getUser!==null){
        if(to.name=='login') next({name:'panelInicio'});
        else next();
      }else{
        next({name: 'login'});
      }
    }else{
      next();
    }

  }else{
    next();
  }

  next();
})



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
