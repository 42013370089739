<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDarkTheme"
      @click="$vuetify.theme.dark = !$vuetify.theme.dark"
    >
      {{!isDarkTheme ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  mounted() {
    let themeDark = this.$storage.get('theme.dark',{boolean:true,default:false});
    this.$vuetify.theme.dark = themeDark;
  },
  computed: {
    isDarkTheme(){
      return this.$vuetify.theme.dark;
    }
  },
  watch: {
    isDarkTheme(newValue,oldValue){
      if (newValue!==oldValue) {
        this.$storage.set('theme.dark',newValue,{boolean:true});
      }
    }
  }
}
</script>

<style>
</style>
