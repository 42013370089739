<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  mounted(){
    let themeDark = this.$storage.get('theme.dark',{boolean:true,default:false});
    this.$vuetify.theme.dark = themeDark;
  }
}
</script>

<style>
</style>
