<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="getImage"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="getImage"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{getFullName}}
          </span>
          <small class="text--disabled text-capitalize">{{getCompany}}</small>
        </div>
      </div>

<v-divider class="my-2"></v-divider>

      <!-- Profile -->
      <v-list-item link :to="{name: 'cuenta'}">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- FAQ -->
      <!-- <v-list-item link @click="$store.dispatch('set_bottom_sheet',true)">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Ayuda</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import helpers from '../../mixins/helpers';

export default {
  mixins: [helpers],
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods: {
    logout() {
      let url = `${this.$base_api_url}/logout`;

      this.$http.post(url).then(()=>{
        this.$router.push({name: 'login'})
      }).catch(()=>{
        this.$swal.fire({
          title: `Error`,
          text: `No se pudo finalizar la sesión, intenta mas tarde`,
          icon: `error`
        })
      });
    }
  },
  computed: {
    getImage(){
      let user =  this.$store.getters.getUser;
      if (user!=null && user.img_company!=null){
        return this.storagePrivateLinkApi(user.img_company);
      }else{
        return require('@/assets/images/no-user.png')
      }
    },
    getFullName(){
      let user = this.$store.getters.getUser;

      if (user) {
        return user.name + ' '+ user.last_name;
      }else{
        return '';
      }
    },
    getCompany(){
      let user = this.$store.getters.getUser;

      if (user) {
        return user.company;
      }else{
        return '';
      }
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
