const storage = {
  get(key, options) {
    options = this.formatData(options);

    if (!this.checkCompatibility()) {

      return options.default;
    }

    let item = localStorage.getItem(key);


    if (item == null) {
      return options.default;
    }

    if (options.json) {
      return JSON.parse(item);
    } else if (options.boolean) {
      return item == '1' ? true : false;
    } else {
      return item;
    }
  },
  set(key, value, options) {
    options = this.formatData(options);

    if (!this.checkCompatibility()) {

      return false;
    }

    if (options.json) {
      localStorage.setItem(key, JSON.stringify(value));
    } else if (options.boolean) {
      return localStorage.setItem(key,value?1:0)
    } else {
      localStorage.setItem(key, value);
    }
  },
  remove(key) {
    if (!this.checkCompatibility()) {

      return false;
    }

    localStorage.removeItem(key)

    return true;
  },
  removeAll() {
    if (!this.checkCompatibility()) {

      return false;
    }
    localStorage.clear()
    return true;
  },
  checkCompatibility() {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem('feature_test', 'yes');
        if (localStorage.getItem('feature_test') === 'yes') {
          localStorage.removeItem('feature_test');
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  },
  formatData(data) {
    let defaultData = {
      json: false,
      boolean: false,
      default: null,
    }

    if (data !== undefined) {
      if (data.json !== undefined) {
        if (typeof data.json === 'boolean') {
          defaultData.json = data.json;
        }
      }

      if (data.boolean !== undefined) {
        if (typeof data.boolean === 'boolean') {
          defaultData.boolean = data.boolean;
        }
      }

      if (data.default !== undefined) {
        defaultData.default = data.default;
      }
    }

    return defaultData;
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$storage = storage
  }
}
