import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/panel-de-inicio',
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('@/app/inventario/inventario.vue'),
  },
  {
    path: '/catalogo',
    name: 'catalogo',
    component: () => import('@/app/Catalogo/Catalogo.vue'),
  },
  {
    path: '/seguimiento',
    name: 'traking',
    component: () => import('@/app/Traking/Traking.vue'),
  },
  {
    path: '/ampliar-mi-almacenamiento',
    name: 'ampliarMiAlmacenamiento',
    component: () => import('@/app/ampliarMiAlmacenamiento/ampliarMiAlmacenamiento'),
  },
  {
    path: '/cuenta',
    name: 'cuenta',
    component: () => import('@/app/Cuenta/Cuenta.vue'),
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: () => import('@/app/Politicas/Politicas.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/panel-de-inicio',
    name: 'panelInicio',
    component: () => import('@/app/Panel/panel.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/app/Auth/login.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/recuperar-contrasena',
    name: 'recuperar-contrasena',
    component: () => import('@/app/Auth/recuperar-contrasena.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
  {
    path: '/recuperar-contrasena/:token',
    name: 'resetear-contrasena',
    component: () => import('@/app/Auth/resetear-contrasena.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },


  {
    path: '/registro',
    name: 'register',
    component: () => import('@/app/Auth/register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      auth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
