import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
  },
  state: {
    bottomSheet: false,
  },
  mutations: {
    set_bottom_sheet(state, value) {
      state.bottomSheet = value
    },
  },
  actions: {
    set_bottom_sheet({ commit }, value) {
      commit('set_bottom_sheet', value);
    },
  },
  getters: {
    getBottomSheet(state){
      return state.bottomSheet;
    }
  }
})
